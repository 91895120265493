
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/info/tokens/[address]",
      function () {
        return require("private-next-pages/info/tokens/[address].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/info/tokens/[address]"])
      });
    }
  